import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  RendererFactory2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentDataSource } from './document-sidebar.datasource';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export interface SidebarPage {
  pageNumber: number;
  img: string;
}

@Component({
  selector: 'et-atoms-document-sidebar',
  templateUrl: './document-sidebar.component.html',
  styleUrls: ['./document-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentSidebarComponent implements OnInit, OnChanges {
  @HostBinding('style.width.px')
  @Input()
  width = 226;

  @Input() minBufferPx = 100;
  @Input() maxBufferPx = 200;

  @Input() pdfFile: File | string | undefined;

  @Input() selectedPage: number | null = 0;

  @Output() paggeSelected = new EventEmitter<number>();

  @ViewChild(CdkVirtualScrollViewport)
  scrollViewport!: CdkVirtualScrollViewport;

  preventScroll = false;

  dataSource!: DocumentDataSource;

  constructor(private rendererFactory: RendererFactory2) {}

  ngOnInit(): void {
    this.initDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Scroll to selected page
    if (changes['selectedPage'] && this.scrollViewport) {
      if (this.preventScroll) {
        this.preventScroll = false;
        return;
      }
      const previousPage = changes['selectedPage'].previousValue;
      const currentPage = changes['selectedPage'].currentValue;
      const delta = Math.abs(previousPage - currentPage);
      const opt = delta > 4 ? 'auto' : 'smooth';
      const scrollToIndex = changes['selectedPage']?.currentValue - 2 || 0;
      this.scrollViewport.scrollToIndex(scrollToIndex, opt);
    }
  }

  /**
   * It initializes data source for virtual scroll
   * @returns
   */
  initDataSource() {
    if (!this.pdfFile) return;
    const renderer = this.rendererFactory.createRenderer(null, null);
    // Init datasource
    const dataSource = new DocumentDataSource(this.pdfFile, renderer);
    // Set datasource
    this.dataSource = dataSource;
  }

  /**
   * It takes page number and emits it to parent component
   * @param pageNumber - page number
   * @returns
   */
  onSelectPage(pageNumber: number) {
    if (pageNumber === this.selectedPage) return;
    this.preventScroll = true;
    this.paggeSelected.emit(pageNumber);
  }

  /**
   * It is a track by function for virtual scroll
   * @param _ - unused
   * @param page - page
   * @returns - page number
   */
  trackBy(_: unknown, page: SidebarPage) {
    return page.pageNumber;
  }
}
