export enum platformType {
  ELECTRON = 'electron',
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android',
}

export enum signingModes {
  DRAWING = 'drawing',
  NOTARY = 'notary',
}

export enum DocumentMarkupStatus {
  MARKEDUP = 'Markedup',
  PROCESSING = 'Processing',
  PROCESSED = 'Processed',
  NEW = 'New',
}

export const defaultPenColor = '#1941A3';
export const signorOneMarkerFill = '#FFE064';
export const signorTwoMarkerFill = '#94F0FF';
export const signorThreeMarkerFill = '#FFAC7C';
export const signorFourMarkerFill = '#D49CFF';
export const notaryMarkerFill = '#B4F0CC';
export const defaultMarkerFill = '#E4E0C8';
export const nativeMarkingOpacity = 0.998;

export const ENOTE_PAGES_FOR_SIGNING = [4];

export const DEFAULT_STAMP_PATH = 'assets/images/stamp-default.svg';

export const defaultUWPBrushSettings = {
  width: 3,
  pressureCoeff: 80,
  simplifyTolerance: 0.1,
  simplifyOnRender: false,
  min: 0.25,
};

export const defaultIOSBrushSettings = {
  width: 3,
  pressureCoeff: 75,
  simplifyTolerance: 0.19,
  simplifyOnRender: true,
  min: 0.75,
};

export const STAMP_SCALE_RATIO = 0.3;

export const NATIVE_AUDITS_ERROR_MESSAGE =
  'Audit Log failed to submit. Please check your connection and try again or email support@escrowtab.com if the issue persists.';
export const NATIVE_DOC_SUBMIT_ERROR_MESSAGE =
  'Document failed to submit. Please check your connection and try again or email support@escrowtab.com if the issue persists.';
export const NATIVE_ANNOTATION_ERROR_MESSAGE =
  'Document failed to submit Annotation Log. Please check your connection and try again or email support@escrowtab.com if the issue persists.';
export const NATIVE_UPDATE_STATUS_ERROR_MESSAGE =
  'Document failed to submit. Please check your connection and try again or email support@escrowtab.com if the issue persists.';
