<!-- Header -->
<div class="px-3 py-2">
  <img src="assets/images/file.svg" alt="File" width="20" height="20" />
  <hr class="mt-2 border-neutral-secondary" />
</div>

<!-- Pages scroll container -->
<cdk-virtual-scroll-viewport
  itemSize="226"
  [minBufferPx]="minBufferPx"
  [maxBufferPx]="maxBufferPx"
  class="flex flex-col items-center justify-start flex-grow w-full gap-3 px-4"
>
  <div
    *cdkVirtualFor="
      let page of dataSource;
      let cdkVirtualForTrackBy = trackBy;
      let i = index
    "
    class="flex flex-col items-center gap-1"
  >
    <ng-container
      *ngTemplateOutlet="
        page ? pageLoaded : pageLoading;
        context: { i, page, selectedPage }
      "
    >
    </ng-container>
  </div>

  <!-- Page -->
  <ng-template #pageLoaded let-page="page" let-selectedPage="selectedPage">
    <img
      [src]="page.img"
      [ngClass]="{
        '!border-[#00C494] ': selectedPage === page.pageNumber,
        'cursor-pointer': selectedPage !== page.pageNumber
      }"
      (click)="onSelectPage(page.pageNumber)"
      class="transition-all border-2 border-transparent"
      tabindex="0"
      alt="Page  {{ page.pageNumber }}"
    />
    <span>{{ page.pageNumber }}</span>
  </ng-template>

  <!-- Page placeholder -->
  <ng-template #pageLoading let-i="i">
    <div
      class="flex items-center justify-center m-2 w-[150px] h-[200px] border border-secondary-focus"
    >
      <et-atoms-spinner></et-atoms-spinner>
    </div>
    <span>{{ i + 1 }}</span>
  </ng-template>
</cdk-virtual-scroll-viewport>
